$primary50: hsla(228, 100%, 98%, 1);
$primary100: hsla(225, 100%, 94%, 1);
$primary200: hsla(225, 100%, 88%, 1);
$primary300: hsla(225, 100%, 80%, 1);
$primary400: hsla(225, 100%, 72%, 1);
$primary500: hsla(225, 100%, 64%, 1);
$primary600: hsla(225, 64%, 56%, 1);
$primary700: hsla(225, 64%, 36%, 1);
$primary800: hsla(225, 64%, 24%, 1);
$primary900: hsla(225, 64%, 16%, 1);

$white: hsla(0, 0%, 100%, 1);
$black: hsla(0, 0%, 0%, 1);

$destructive50: hsla(5, 86%, 97%, 1);
$destructive100: hsla(4, 93%, 94%, 1);
$destructive200: hsla(4, 96%, 89%, 1);
$destructive400: hsla(4, 92%, 69%, 1);
$destructive500: hsla(4, 86%, 58%, 1);
$destructive600: hsla(4, 74%, 49%, 1);
$destructive700: hsla(4, 76%, 40%, 1);
$destructive800: hsla(4, 86%, 33%, 1);

$neutral20: hsla(230, 16%, 98%, 1);
$neutral30: hsla(230, 16%, 97%, 1);
$neutral40: hsla(230, 16%, 96%, 1);
$neutral60: hsla(230, 16%, 94%, 1);
$neutral80: hsla(230, 16%, 90%, 1);
$neutral100: hsla(230, 16%, 80%, 1);
$neutral200: hsla(230, 16%, 72%, 1);
$neutral400: hsla(230, 16%, 56%, 1);
$neutral500: hsla(230, 16%, 40%, 1);
$neutral600: hsla(230, 16%, 40%, 1);
$neutral700: hsla(230, 16%, 32%, 1);
$neutral800: hsla(230, 16%, 24%, 1);

$success50: hsla(145, 81%, 96%, 1);
$success100: hsla(140, 80%, 90%, 1);
$success400: hsla(150, 66%, 52%, 1);
$success500: hsla(152, 82%, 39%, 1);
$success600: hsla(153, 96%, 30%, 1);
$success800: hsla(155, 90%, 20%, 1);

$warning50: hsla(45, 100%, 96%, 1);
$warning100: hsla(45, 96%, 89%, 1);
$warning400: hsla(39, 98%, 56%, 1);
$warning500: hsla(34, 94%, 50%, 1);
$warning600: hsla(28, 97%, 44%, 1);
$warning700: hsla(22, 92%, 37%, 1);
$warning800: hsla(19, 84%, 31%, 1);

$boxShadowXS: 0px 1px 2px rgba(34, 36, 47, 0.16);
$boxShadowMD: 0px 4px 8px rgba(34, 36, 47, 0.16);
$boxShadowLG: 0px 8px 16px rgba(34, 36, 47, 0.16);
$boxShadowXL: 0px 12px 24px rgba(34, 36, 47, 0.16);

:export {
    //primary
    primary50: $primary50;
    primary100: $primary100;
    primary200: $primary200;
    primary300: $primary300;
    primary400: $primary400;
    primary500: $primary500;
    primary600: $primary600;
    primary700: $primary700;
    primary800: $primary800;
    primary900: $primary900;
    //white
    white: $white;
    black: $black;
    //destructive
    destructive50: $destructive50;
    destructive100: $destructive100;
    destructive200: $destructive200;
    destructive400: $destructive400;
    destructive500: $destructive500;
    destructive600: $destructive600;
    destructive700: $destructive700;
    destructive800: $destructive800;
    //neutral
    neutral20: $neutral20;
    neutral30: $neutral30;
    neutral40: $neutral40;
    neutral60: $neutral60;
    neutral80: $neutral80;
    neutral100: $neutral100;
    neutral200: $neutral200;
    neutral400: $neutral400;
    neutral500: $neutral500;
    neutral600: $neutral600;
    neutral700: $neutral700;
    neutral800: $neutral800;
    //success
    success50: $success50;
    success100: $success100;
    success400: $success400;
    success500: $success500;
    success600: $success600;
    success800: $success800;
    //warning
    warning50: $warning50;
    warning100: $warning100;
    warning400: $warning400;
    warning500: $warning500;
    warning600: $warning600;
    warning700: $warning700;
    warning800: $warning800;
    //boxShadow
    boxShadow: $boxShadowXS;
    boxShadowMD: $boxShadowMD;
    boxShadowLG: $boxShadowLG;
    boxShadowXL: $boxShadowXL;
}

.text,
.heading,
.button,
.display,
.overline {
    font-family: 'Figtree' !important;
    font-style: normal !important;
    text-align: left;
}

.display--xs {
    font-size: 48px !important;
    line-height: 64px !important;
}

.button--xs {
    font-size: 12px !important;
    line-height: 24px !important;
}

.button--sm {
    font-size: 14px !important;
    line-height: 24px !important;
}

.heading--xs {
    font-size: 20px !important;
    line-height: 28px !important;
}

.heading--sm {
    font-size: 24px !important;
    line-height: 32px !important;
}

.heading--md {
    font-size: 28px !important;
    line-height: 40px !important;
}

.overline--xs {
    font-size: 12px !important;
    line-height: 20px !important;
}

.text--sm {
    font-size: 12px !important;
    line-height: 20px !important;
}

.text--md {
    font-size: 14px !important;
    line-height: 20px !important;
}

.text--lg {
    font-size: 16px !important;
    line-height: 24px !important;
}

.text--xl {
    font-size: 18px !important;
    line-height: 24px !important;
}

.typography--bold {
    font-weight: 700 !important;
}

.typography--semibold {
    font-weight: 600 !important;
}

.typography--medium {
    font-weight: 500 !important;
}

.typography--regular {
    font-weight: 400 !important;
}

.text-neutral--200 {
    color: $neutral200 !important;
}

.typography-neutral--40 {
    color: $neutral40 !important;
}

.typography-neutral--200 {
    color: $neutral200 !important;
}

.typography-neutral--400 {
    color: $neutral400 !important;
}

.typography-neutral--500 {
    color: $neutral500 !important;
}

.typography-neutral--600 {
    color: $neutral600 !important;
}

.typography-neutral--700 {
    color: $neutral700 !important;
}

.typography-neutral--800 {
    color: $neutral800 !important;
}

.typography-primary--500 {
    color: $primary500 !important;
}

.typography-primary--600 {
    color: $primary600 !important;
}

.typography-primary--800 {
    color: $primary800 !important;
}

.typography-destructive--400 {
    color: $destructive400 !important;
}

.typography--black {
    color: $black !important;
}

.typography--white {
    color: $white !important;
}

.text-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.neutral-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid $neutral80;
    background: $white;

    &--padding-large {
        @extend .neutral-container;
        padding: 24px;
        gap: 16px;
    }

    &--padding-medium {
        @extend .neutral-container;
        padding: 16px;
        gap: 16px;
    }

    &--padding-small {
        @extend .neutral-container;
        padding: 8px;
        gap: 8px;
    }
}

.primary-container {
    display: flex;
    flex-direction: row;
    align-items: flex;
    justify-content: space-between;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid $primary200;
    background: $white;
    padding: 8px 16px;
}
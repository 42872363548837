@import '../utils/index.module.scss';

.sidebar-row {
    height: 40px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    border-radius: 12px;
    color: $neutral500;

    &:hover {
        background-color: $white;
        box-shadow: $boxShadowXS;
        color: $neutral600
    }

    &--active {
        background-color: $white;
        border: 1px solid $primary300;
        color: $primary500;
    }
}